import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface MediaState {
  value: boolean
}

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  constructor() { }

  media$ = new Subject<MediaState>();

  set(update: MediaState) {
    this.media$.next(update)
  }
  get() {
    return this.media$
  }
}
