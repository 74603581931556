<div class="container-fluid header-font">
  <div class="row d-flex flex-nowrap p-2 bg-info white-text">
    <div class="mt-1 mr-4">
      <mdb-icon (click)="toggleMenu()" fas icon="bars" size="2x"></mdb-icon>
    </div>
    <div>
      <h4 class="mt-1 mr-2" *ngIf="showTitle">
        {{ title }}
      </h4>
    </div>
    <div class="mt-1 ml-auto">
      <mdb-icon fas icon="undo" size="2x" [hidden]="hideRestartBtn" (click)="confirmResetModal.show()"></mdb-icon>
    </div>
  </div>
  
  <!-- Confirm Reset Course Modal -->
  <div mdbModal #confirmResetModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="confirmResetModal" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header bg-info white-text">
          <h4 class="modal-title w-100" id="confirmModalLabel">Beginning of Course</h4>
        </div>
        <div class="modal-body">
          Do you wish to go to the beginning of this course?
        </div>
        <div class="modal-footer justify-content-center">
          <button mdbBtn color="info" class="waves-light" mdbWavesEffect (click)="restart()">Yes</button>
          <button type="button" mdbBtn color="info" class="relative waves-light" (click)="confirmResetModal.hide()" mdbWavesEffect>
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</div>