import { createAction, props } from '@ngrx/store'

export const getConfig = createAction(
    'Get Config'
)
export const setConfig = createAction(
    'Set Config',
    props<{ config }>()
)
export const loadState = createAction(
    'Load State',
    props<{ remoteState }>()
)
export const setOrigin = createAction(
    'Set Origin',
    props<{ origin }>()
)
export const getProgress = createAction(
    'Get Progress'
)
export const setProgress = createAction(
    'Set Progress',
    props<{ chapter, page, bool }>()
)
export const clearProgress = createAction(
    'Clear Progress'
)
export const loadProgress = createAction(
    'Load Progress',
    props<{ progress }>()
)
export const setProgressLinear = createAction(
    'Set Progress Linear',
    props<{ linear }>()
)

export const addAttempt = createAction(
  'Add Attempt'
)
export const clearAttempts = createAction(
  'Clear Attempts'
)
export const getBookmark = createAction(
    'Get Bookmark'
)
export const setBookmark = createAction(
    'Set Bookmark',
    props<{ bookmark }>()
)
export const getScore = createAction(
    'Get Score'
)
export const loadScore = createAction(
  'Load Score',
  props<{ score }>()
)
export const setScore = createAction(
    'Set Score',
    props<{ score }>()
)
export const getStatus = createAction(
    'Get Status'
)
export const setStatus = createAction(
    'Set Status',
    props<{ status }>()
)
export const doNothing = createAction(
    'Do Nothing'
)