import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import videojs from "video.js";
import canauto from "can-autoplay";
import { MediaService } from "../../services/media.service";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";

@Component({
  selector: "app-playlist",
  templateUrl: "./playlist.component.html",
  styleUrls: ["./playlist.component.scss"],
})
export class PlaylistComponent implements OnInit, AfterViewInit {
  @Input() data: any = {};
  @Output() autoplay: EventEmitter<any> = new EventEmitter();

  player: videojs.Player;
  baseUrl = "";
  index = null;
  initial = true;
  narration;

  constructor(private store: Store, private media: MediaService) {}

  ngOnInit() {
    window.addEventListener("message", (e: any) => {
      if (e.data && e.data.narrate >= 0) {
        // console.log('playlist message 2', e)
        this.handleAudio(e.data.narrate);
      }
    });

    this.narration = this.data.narration[0].file.length - 1;

    this.store
      .pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      )
      .subscribe((state: any) => {
        if (state.config.baseUrl) {
          this.baseUrl = state.config.baseUrl;
        } else {
          this.baseUrl = "../../../assets/media-content/";
        }
      });
  }

  ngAfterViewInit() {
    this.player = videojs("player");
  }

  handleAudio(index) {
    if (index === this.index) {
      return;
    }
    /*
      check here for last index, then enable next scrubber button
      this.data.narration.length -1
    */
    this.player.pause();
    // console.log(index, this.initial)
    this.player.src({
      src: this.playlist[index].src,
      type: "audio/mp3",
    });
    this.player.load();
    if (index === 0 && this.initial === true) {
      this.initial = false;
      canauto.video().then(({ result }) => {
        if (result === true) {
          const play = this.player.play();
          play.catch((error) => console.error(error));
          this.autoplay.emit({ autoplay: true });
        } else {
          this.autoplay.emit({ autoplay: false });
          this.player.one("play", () => {
            // console.log('play event');
            this.autoplay.emit({ screen: false });
          });
        }
      });
    } else {
      const play = this.player.play();
      play.catch((error) => console.error(error));
    }
    this.index = index;

    if (this.narration === this.index) {
      this.player.on("ended", () => {
        let completed = this.player.ended();
        let mediaObject = {
          value: completed,
        };
        this.media.set(mediaObject);
      });
    }
  }

  get playlist() {
    return this.data.narration[0].file.map((el) => {
      return {
        src: this.baseUrl + this.data.course + "/audio/" + el,
      };
    });
  }
}
