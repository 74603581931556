<audio id="player" autoplay class="video-js vjs-default-skin vjs-nofull" controls>
</audio>











