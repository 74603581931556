import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScormBusComponent } from './scorm-bus/scorm-bus.component';



@NgModule({
  declarations: [ScormBusComponent],
  imports: [
    CommonModule,
    HttpClientModule
  ],
   exports: [
     ScormBusComponent
   ]
})
export class ScormBusModule { }
