import { Component, OnInit, Input, HostListener, ViewChild } from '@angular/core';

@Component({
  selector: 'app-interactive-hero',
  templateUrl: './interactive-hero.component.html',
  styleUrls: ['./interactive-hero.component.scss']
})
export class InteractiveHeroComponent implements OnInit {
  @Input() data: any = {};
  @Input() course = '';
  @ViewChild('playlist') playlist;
  autoplay = false;
  screen = true;
  constructor() { }

  ngOnInit() {
  }
  handleLoad(e) {
    if (this.playlist) {
      this.playlist.index = null;
      this.playlist.handleAudio(0);
    }
  }
  handleAutoplay(e) {
    console.log('handle autoplay', e)
    if (e.hasOwnProperty('autoplay')) {
      this.autoplay = e.autoplay;
      if (e.autoplay === true) {
        this.screen = false;
      }
    }
    if (e.hasOwnProperty('screen')) {
      this.screen = false;
    }
  }
  get media() {
    return (this.data.hasOwnProperty('media')) ? {
      course: this.course,
      file: this.data.media[0].file[0],
      narration: this.data.media[0].narration
    } : {};
  }
}
