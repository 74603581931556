import { environment } from './../../environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { first, tap, map } from 'rxjs/operators';
import { parseString } from 'xml2js'

@Injectable({
  providedIn: 'root'
})
export class ParseMediaService {
  headers = new HttpHeaders();
  // parseString = xml2js.parseString;

  constructor(
    private http: HttpClient
  ) { }
  url(params) {
    const url = [
      '../../assets',
      environment.mediaLocation,
      params.course,
      'course.xml'
    ].join('/');
    return url;
  }

  parse(xml) {
    let out;
    parseString(xml, (err, result) => {
        out = result;
    });
    return out;
  }

  getCourse(params) {
    return this.http.get(this.url(params), { responseType: 'text'})
      .pipe(first(), map(data => this.parse(data)));
  }
}
