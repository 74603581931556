<!-- Toggled Menu -->
<mdb-side-nav [sidenavBreakpoint]="4096" class="info-color">
  <mdb-icon
    fas
    icon="angle-left"
    size="2x"
    class="mt-2 ml-2"
    (click)="toggle()"
  ></mdb-icon>

  <ul class="collapsible collapsible-accordion">
    <mdb-accordion
      [multiple]="false"
      aria-multiselectable="false"
      *ngIf="course"
    >
      <!-- Collapsible link -->
      <mdb-accordion-item
        *ngFor="let chapter of content.chapter; let c = index"
        class="menu-chapter active"
      >
        <mdb-accordion-item-head
          *ngIf="!noPostAssessment || c < content.chapter.length - 1"
        >
          {{ chapter.$.title }}
        </mdb-accordion-item-head>
        <mdb-accordion-item-body>
          <ul>
            <li
              *ngFor="let page of chapter.page; let p = index"
              [class.disabled]="disableSideNav(c, p)"
            >
              <a
                class="menu-link"
                [routerLink]="'../../../' + url(c, p)"
                routerLinkActive="active"
                [class.disabled]="disableSideNav(c, p)"
              >
                {{ page.nav[0] }}
              </a>
            </li>
          </ul>
        </mdb-accordion-item-body>
      </mdb-accordion-item>
    </mdb-accordion>
  </ul>
</mdb-side-nav>
