import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";

@Component({
  selector: "app-interactive",
  templateUrl: "./interactive.component.html",
  styleUrls: ["./interactive.component.scss"],
})
export class InteractiveComponent implements OnInit, AfterViewInit {
  @Input() data: any = {};
  @ViewChild("container") container;
  @Output() load: EventEmitter<any> = new EventEmitter();

  baseUrl = "";

  constructor(private store: Store) {}

  ngOnInit() {
    this.store
      .pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      )
      .subscribe((state: any) => {
        if (state.config.baseUrl) {
          this.baseUrl = state.config.baseUrl;
        } else {
          this.baseUrl = "../../../assets/media-content/";
        }
      });
  }
  ngAfterViewInit() {
    // console.log(this.container)
    this.container.nativeElement.src = this.src;
  }
  get src() {
    return (
      this.baseUrl +
      this.data.course +
      "/page/" +
      this.data.file +
      "/index.html"
    );
  }
}
