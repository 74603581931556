import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-video-columns',
  templateUrl: './video-columns.component.html',
  styleUrls: ['./video-columns.component.scss']
})
export class VideoColumnsComponent implements OnInit {
  @Input() data: any = {};
  @Input() course = '';
    constructor() { }

    ngOnInit() {
    }
    get media() {
      return (this.data.hasOwnProperty('copy')) ? {
        course: this.course,
        video: this.data.media[0].file[0],
        // width: 400,
        // height: 400
      } : {};
    }

}
