import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() data: any = {};
  @Input() meta: any = {};
  @Input() course = '';
  @Output() menu: any = new EventEmitter();

  hideRestartBtn: boolean = false;
  currentRoute;
  currentCourse;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    ) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(data => {
      this.currentRoute = [data.course, data.chapter, data.page].join('/');
      this.currentCourse = this.course + '/' + '0' + '/' + '0';
    })
  }
  ngAfterViewInit() {
    if (this.currentRoute === this.currentCourse) {
      setTimeout(() => this.hideRestartBtn = true);
    }
  }
  toggleMenu() {
    this.menu.emit({ clicked: true});
  }
  get showTitle() {
    if (this.meta.hasOwnProperty('page')) {
      return true;
    }
    return false;
  }
  get title() {
    return [
      this.meta.course,
      this.meta.chapter,
      this.meta.page
    ].join(' / ');
  }
  restart() {
    let hashedCourse;
    hashedCourse = '#/' + this.currentCourse;

    if (this.currentCourse) {
      this.router.navigate([this.currentCourse]);
    } else if ('#/' + this.currentCourse) {
      this.router.navigate([hashedCourse]);
    }
  }
}
