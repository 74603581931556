import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioComponent } from './audio/audio.component';
import { VideoComponent } from './video/video.component';
import { InteractiveComponent } from './interactive/interactive.component';
import { PlaylistComponent } from './playlist/playlist.component';



@NgModule({
  declarations: [AudioComponent, VideoComponent, InteractiveComponent, PlaylistComponent],
  imports: [
    CommonModule
  ],
  exports: [AudioComponent, VideoComponent, InteractiveComponent, PlaylistComponent]
})
export class MediaModule { }
