import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image-columns',
  templateUrl: './image-columns.component.html',
  styleUrls: ['./image-columns.component.scss']
})
export class ImageColumnsComponent implements OnInit {
  @Input() data: any = {};
  @Input() course = '';

  constructor() { }

  ngOnInit() {
  }

  get media() {
    return (this.data.hasOwnProperty('copy')) ? {
      image: this.data.media[0].file,
      course: this.course,
      audio: this.data.media[0].narration,
    } : {};
  }

}
