<div class="container mt-5">
  <div class="row">
    <div class="col-md-12 text-center">
      <div class="error-template">
        <h1>Oops</h1>
        <h2>404 - Not Found</h2>
        <div class="error-details">
          Sorry, an error has occured, Requested page not found.
        </div>
        <div class="error-actions mt-5">
          <a routerLink="/BA-EN/0/0" class="btn btn-primary btn-lg">
            <mdb-icon fas icon="home"></mdb-icon>
            Take Me Home 
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
