import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-interactive-simple',
  templateUrl: './interactive-simple.component.html',
  styleUrls: ['./interactive-simple.component.scss']
})
export class InteractiveSimpleComponent implements OnInit {
  @Input() data: any = {};
  @Input() course = '';

  constructor() { }

  ngOnInit() {
  }
  get media() {
    return (this.data.hasOwnProperty('media')) ? {
      course: this.course,
      file: this.data.media[0].file[0],
      narration: this.data.media[0].narration
    } : {};
  }

}
