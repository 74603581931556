<div class="iframe-container">
  <iframe #container src="" class="responsive-iframe" (load)="load.emit($event)"></iframe>
</div>







