<app-header
  (menu)="toggleMenu()"
  [data]="page$"
  [meta]="meta"
  [course]="course"
></app-header>
<app-navigation [data]="json$" [route]="route$"></app-navigation>

<div [ngSwitch]="layout" class="container-fluid p-5">
  <app-image-columns
    *ngSwitchCase="'image-columns'"
    class="row"
    [data]="page$"
    [course]="course"
  ></app-image-columns>
  <app-video-columns
    *ngSwitchCase="'video-columns'"
    class="row"
    [data]="page$"
    [course]="course"
  ></app-video-columns>
  <app-video-hero
    *ngSwitchCase="'video-hero'"
    class="row"
    [data]="page$"
    [course]="course"
  ></app-video-hero>
  <app-interactive-hero
    *ngSwitchCase="'interactive-hero'"
    class="row"
    [data]="page$"
    [course]="course"
  ></app-interactive-hero>
  <app-interactive-simple
    *ngSwitchCase="'interactive-simple'"
    class="row"
    [data]="page$"
    [course]="course"
  ></app-interactive-simple>
  <app-post-assessment
    *ngSwitchCase="'post-assessment'"
    class="row"
    [data]="page$"
    [course]="course"
  ></app-post-assessment>
  <div *ngSwitchDefault class="row mt-5">
    <div class="col text-center">Loading...</div>
  </div>

  <div class="row bg-white fixed-bottom">
    <div class="col text-left">
      <a
        mdbBtn
        floating="true"
        size="lg"
        color="info"
        mdbWavesEffect
        [href]="prevPage"
        *ngIf="this.prevPage === '' ? false : true"
      >
        <mdb-icon fas icon="arrow-left" size="sm"></mdb-icon>
      </a>
    </div>
    <div class="col text-right">
      <a
        mdbBtn
        floating="true"
        size="lg"
        color="info"
        mdbWavesEffect
        [href]="nextPage"
        *ngIf="this.showBtn"
        [class.disabled]="disableBtn"
      >
        <mdb-icon fas icon="arrow-right" size="sm"></mdb-icon>
      </a>
    </div>
  </div>
</div>
