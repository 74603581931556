import { ScormBusModule } from './../scorm-bus/scorm-bus.module';
import { PagesModule } from './../pages/pages.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentComponent } from './content/content.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HeaderComponent } from './header/header.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SidenavModule, WavesModule, AccordionModule, IconsModule, ModalModule, InputsModule, ButtonsModule, NavbarModule } from 'ng-uikit-pro-standard';
import { RouterModule } from '@angular/router';




@NgModule({
  declarations: [ContentComponent, PageNotFoundComponent, HeaderComponent, NavigationComponent],
  imports: [
    CommonModule,
    PagesModule,
    ScormBusModule,
    SidenavModule,
    IconsModule,
    WavesModule,
    AccordionModule,
    ModalModule,
    InputsModule,
    ButtonsModule,
    NavbarModule,
    RouterModule
  ],
  exports: [
    NavigationComponent, HeaderComponent
  ]
})
export class LayoutModule { }
