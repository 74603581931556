import { createReducer, on, Action } from '@ngrx/store'
import { addAttempt, clearAttempts, clearProgress, getProgress, loadProgress, loadScore, loadState, setBookmark, setConfig, setOrigin, setProgress, setProgressLinear, setScore, setStatus } from './actions'
import { ConfigModel, CourseStateModel } from './model'

export const initialState: CourseStateModel = {
    config: null,
    progress: {
        attempts: 0,
        linear: false,
    },
    score: {intScore: 0, intMaxScore: 100, intMinScore: 0, passed: null},
    status: {passed: null},
    origin: null,
    hydrated: false
}

export const stateReducer = createReducer(
    initialState,
    on(loadState, (state, { remoteState }) => {
        return {
            ...state,
            config: remoteState.config,
            progress: {
              ...state.progress,
              ...remoteState.progress
            },
            score: remoteState.score,
            status: remoteState.status,
            hydrated: true
        }
    }),
    on(setConfig, (state, { config }) => {
        return {
            ...state,
            ...{
                config
            }
        }
    }),
    on(setOrigin, (state, { origin }) => {
        return {
            ...state,
            ...{
                origin
            }
        }
    }),
    on(setProgress, (state, { chapter, page, bool }) => {
        let key = [chapter, page].join("-");
        
        return {
          ...state,
              progress: Object.assign({}, state.progress, { [key]: bool })
        } 
    }),
    on(loadProgress, (state, { progress }) => {
        return {
            ...state,
            progress: Object.assign({}, progress, state.progress)
        }
    }),
    on(setProgressLinear, (state, { linear }) => {
        return {
            ...state,
            ...{
                progress: {
                    ...state.progress,
                    linear
                }
            }
        }
    }),
    on(addAttempt, (state) => {
        return {
            ...state,
            ...{
                progress: {
                    ...state.progress,
                    attempts: state.progress.attempts + 1
                }
            }
        }
    }),
    on(clearAttempts, (state) => {
        return {
            ...state,
            ...{
                progress: {
                    ...state.progress,
                    attempts: 0
                }
            }
        }
    }),
    on(setBookmark, (state, { bookmark }) => {
        return {
            ...state,
            ...{
                bookmark
            }
        }
    }),
    on(loadScore, (state, { score }) => {
      return {
        ...state,
        ...{
          score
        }
      }
    }),
    on(setScore, (state, { score }) => {
        let newState: CourseStateModel = state;
        
        if (score.intScore > state.score.intScore) {
          newState.score.intScore = score.intScore
        }
        if (state.config.linearProgression === true) {
          if (newState.score.intScore >= state.config.passingScore) {
            newState.progress.attempts = 0
            newState.status.passed = true
          } else {
            newState.progress.attempts = state.progress.attempts + 1
            newState.status.passed = false
          }

          if (state.progress.attempts === state.config.numberOfAttempts && newState.status.passed === false) {
            newState.progress = {
              '0-0': false,
              attempts: 0,
              linear: true,
            }
          }
        } else {
          if (newState.score.intScore >= state.config.passingScore) {
            newState.status.passed = true
          } else {
            newState.status.passed = false
          }
        }
       
        return newState
    }),
    on(setStatus, (state, { status }) => {
        return {
            ...state,
            ...{
                status
            }
        }
    }),
    on(clearProgress, (state) => {
        return {
            ...state,
            progress: {
                attempts: 0,
                linear: true,
                '0-0': false,
            }
        }
    })
)