import { Component, Input, OnInit } from "@angular/core";
import { filter, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { addAttempt, setScore, setStatus } from "../../scorm-bus/store/actions";
import { fromEvent } from "rxjs";

@Component({
  selector: "app-post-assessment",
  templateUrl: "./post-assessment.component.html",
  styleUrls: ["./post-assessment.component.scss"],
})
export class PostAssessmentComponent implements OnInit {
  @Input() data: any = {};
  @Input() course = "";

  constructor(private store: Store, private route: Router) {}

  iframeListener;
  score;
  lmsScore;
  $config;
  linearProgression: boolean;
  configAttempt: number;
  pdfRequired: boolean;
  defaultScoreScreen: boolean = true;
  passingScore: number;
  attempts: number;

  devInput = 0;

  // Change to true to show buttons for quick testing
  devShow = false;

  ngOnInit() {
    this.iframeListener = fromEvent(window, "message").pipe(
      filter((e: any) => {
        return (
          e.data &&
          typeof e.data === "string" &&
          e.data.indexOf("scorm-bus") !== -1
        );
      }),
      filter((e: any) => {
        return e.data.split(":")[1] === "score";
      })
    );
    this.iframeListener.subscribe((score: any) => {
      let parsedScore = JSON.parse(score.data.split("scorm-bus:score:")[1]);
      // console.log('parsed Score', parsedScore)
      this.store.dispatch(setScore({ score: parsedScore }));
    });

    this.store
      .pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      )
      .subscribe((state: any) => {
        console.log("Config Settings: ", state.config);
        let value = Object.values(state.progress).includes(false);

        if (
          state.progress.linear === true &&
          state.progress.attempts === 0 &&
          value
        ) {
          setTimeout(
            () => (this.route.navigate([this.course + "/" + "0/0"]), 1)
          );
        }

        if (
          state.score.intScore >= state.config.passingScore &&
          state.config.showAssessmentPdf === false
        ) {
          this.defaultScoreScreen = false;
          this.score = state.score.intScore;
        }
      });
  }

  retakeAssessment() {
    return (this.defaultScoreScreen = true);
  }

  devAddAttempt() {
    this.store.dispatch(addAttempt());
  }
  devSetPass() {
    this.store.dispatch(setStatus({ status: { passed: true } }));
  }
  devSetFail() {
    this.store.dispatch(setStatus({ status: { passed: false } }));
  }
  devSetScore() {
    this.store.dispatch(
      setScore({
        score: {
          intScore: this.devInput,
          intMaxScore: 100,
          intMinScore: 0,
          passed: true,
        },
      })
    );
  }

  get media() {
    return this.data.hasOwnProperty("media")
      ? {
          course: this.course,
          file: this.data.media[0].file[0],
          narration: this.data.media[0].narration,
        }
      : {};
  }
}
