import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';

export interface PlatformMessage {
  type: string,
  payload: MessagePayload
}
export interface MessagePayload {
  type: string,
  data?: any
}

@Injectable({
  providedIn: 'root'
})
export class PostService {

  constructor() {}
  $parentWindow = new BehaviorSubject<any>(null);
  $post = new Subject<any>()

  getParentWindow() {
    return this.$parentWindow
  }
  setParentWindow(source) {
    console.log('set parent window')
    this.$parentWindow.next(source)
  }
  getPost() {
    return this.$post
    // .pipe(
    //     map(message => {
    //       console.log('get post', message, this.getParentWindow().getValue())
    //       return { source: this.getParentWindow().getValue(), message }
    //     })
    // )
  }
  submit(message: MessagePayload) {
    // console.log('submit', message)
    this.$post.next({
      type: 'Post from Platform',
      payload: message
    })
  }
}
