<div class="container-fluid pb-2">
    <div class="row">
      <div class="col-lg-6 mt-5 pb-5 mx-auto">
        <div class="media-container">
          <app-interactive [data]="media"></app-interactive>   
        </div> 
      </div>
    </div>
  </div>
  
  
  
  
    
  
  
  