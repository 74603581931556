import { NavigationComponent } from "./../navigation/navigation.component";
import { ParseMediaService } from "./../../scorm-bus/parse-media.service";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { filter, first, map } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { MediaService } from "../../services/media.service";
import { setBookmark, setProgress } from "../../scorm-bus/store/actions";
import { PostService } from "../../scorm-bus/post.service";

@Component({
  selector: "app-content",
  templateUrl: "./content.component.html",
  styleUrls: ["./content.component.scss"],
})
export class ContentComponent implements OnInit {
  @ViewChild(NavigationComponent)
  private navComponent: NavigationComponent;

  constructor(
    private parse: ParseMediaService,
    private route: ActivatedRoute,
    private store: Store,
    private media: MediaService,
    private post: PostService
  ) {}

  route$;
  json$: any = {};
  page$: any = {};
  meta: any = {};
  course = "";
  config;

  $progress;
  progress = {};
  disableBtn: boolean = false;
  noPostAssessment: boolean = false;

  ngOnInit() {
    this.store.dispatch(setBookmark({ bookmark: window.location.hash }));

    this.route.paramMap.pipe(first()).subscribe((data) => {
      this.fetchCourse(data);
    });

    this.store
      .pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      )
      .subscribe((state: any) => {
        if (state.progress.linear === true) {
          this.disableNavBtn(state.progress);
        }
        if (state.config.noPostAssessment) {
          this.noPostAssessment = state.config.noPostAssessment;
        }
      });

    this.media.get().subscribe((subject: any) => {
      if (subject.value === true) {
        this.disableBtn = false;
        this.store.dispatch(
          setProgress({
            chapter: this.route$.chapter,
            page: this.route$.page,
            bool: true,
          })
        );
      }
    });
  }

  disableNavBtn(progress) {
    let key = [this.route$.chapter, this.route$.page].join("-");

    let progressEntries = Object.entries(progress);

    progressEntries.forEach((keyVal: any) => {
      if (keyVal[0] === key && keyVal[1] === true) {
        this.disableBtn = false;
      } else if (keyVal[0] !== key && keyVal[1] !== true) {
        this.disableBtn = true;
      }
    });
  }

  fetchCourse(data) {
    this.route$ = data.params;
    this.course = data.params.course;
    this.parse.getCourse(data.params).subscribe((json) => {
      this.parseContent(json);
    });
  }

  parseContent(json) {
    this.json$ = json;
    const chapter = parseInt(this.route$.chapter, 10);
    const page = parseInt(this.route$.page, 10);
    this.meta = {
      course: this.json$.content.$.title,
      language: this.json$.content.$.language,
      chapter: this.json$.content.chapter[chapter].$.title,
      page: this.json$.content.chapter[chapter].page[page].nav[0],
    };
    this.page$ = this.json$.content.chapter[chapter].page[page];
  }

  toggleMenu() {
    this.navComponent.toggle();
  }

  get layout() {
    return this.page$.hasOwnProperty("$") ? this.page$.$.layout : "";
  }

  get nextPage() {
    const chapter = parseInt(this.route$.chapter, 10);
    const page = parseInt(this.route$.page, 10);
    if (!this.json$.content) {
      return "";
    }
    if (this.json$.content.chapter[chapter].page[page + 1]) {
      return ["#", this.route$.course, chapter, page + 1].join("/");
    } else if (this.json$.content.chapter[chapter + 1]) {
      return ["#", this.route$.course, chapter + 1, 0].join("/");
    }
    return "";
  }

  get prevPage() {
    const chapter = parseInt(this.route$.chapter, 10);
    const page = parseInt(this.route$.page, 10);
    if (!this.json$.content) {
      return "";
    }
    if (this.json$.content.chapter[chapter].page[page - 1]) {
      return ["#", this.route$.course, chapter, page - 1].join("/");
    } else if (this.json$.content.chapter[chapter - 1]) {
      return [
        "#",
        this.route$.course,
        chapter - 1,
        this.json$.content.chapter[chapter - 1].page.length - 1,
      ].join("/");
    }
    return "";
  }

  get showBtn() {
    let out = true;

    if (this.json$.content) {
      const content: any[] = this.json$.content.chapter;
      const chapter = parseInt(this.route$.chapter, 10);
      const secondLast = content.length - 2;
      const isConclusion = chapter === secondLast ? true : false;

      if (this.noPostAssessment && isConclusion) {
        out = false;
      } else if (!this.noPostAssessment && this.nextPage === "") {
        out = false;
      }
    }

    return out;
  }
}
