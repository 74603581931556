import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { filter, first, map } from 'rxjs/operators';
import { PostService } from './scorm-bus/post.service';
import { getConfig, getProgress, getScore } from './scorm-bus/store/actions';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  windowReady = false;
  constructor(
    private post: PostService,
    private store: Store
    ) {
    this.store.pipe(
      ).subscribe((store: any) => {
        if (store.state.hydrated === true && store.state.config !== null) {
        this.windowReady = true 
        }
    })
  }
}
