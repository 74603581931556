import { Injectable } from "@angular/core";
import { ActionsSubject } from "@ngrx/store";
import { PostService } from "../post.service";
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from "rxjs/operators";
import { EMPTY, of } from "rxjs";
import { doNothing, getBookmark } from "./actions";

@Injectable()
export class Effects {
    constructor(
        private actions$: ActionsSubject,
        private post: PostService
    ) {}

    getConfig$ = createEffect(() => this.actions$.pipe(
        ofType('Get Config'),
        switchMap(() => {
          console.log('get config effect')
            this.post.submit({
                type: 'Get Config'
            })
            return of(doNothing())
        })
    ))
    getProgress$ = createEffect(() => this.actions$.pipe(
        ofType('Get Progress'),
        switchMap(() => {
          console.log('get prog')
            this.post.submit({
                type: 'Get Progress'
            })
            return of(doNothing())
        })
    ))
    getBookmark$ = createEffect(() => this.actions$.pipe(
        ofType('Get Bookmark'),
        switchMap(() => {
          console.log('get book')
            this.post.submit({
                type: 'Get Bookmark'
            })
            return of(doNothing())
        })
    ))
    getScore$ = createEffect(() => this.actions$.pipe(
        ofType('Get Score'),
        switchMap(() => {
          console.log('get score effect'
          )
            this.post.submit({
                type: 'Get Score'
            })
            return of(doNothing())
        })
    ))
    getStatus$ = createEffect(() => this.actions$.pipe(
        ofType('Get Status'),
        switchMap(() => {
          console.log('get status')
            this.post.submit({
                type: 'Get Status'
            })
            return of(doNothing())
        })
    ))

}