import { environment } from './../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ParseMediaService } from '../parse-media.service';
import { mockMainListener, config } from '../mockMain';
import { fromEvent, Observable } from 'rxjs';
import { Store } from '@ngrx/store'
import { CourseStateModel } from '../store/model';
import { loadProgress, loadScore, loadState, setBookmark, setConfig, setStatus } from '../store/actions'
import { PostService } from '../post.service';

@Component({
  selector: 'app-scorm-bus',
  templateUrl: './scorm-bus.component.html',
  styleUrls: ['./scorm-bus.component.scss']
})
export class ScormBusComponent implements OnInit, OnDestroy {
  $stream: Observable<Event>
  streamSubscriber;
  postSubscriber;
  $store: Observable<CourseStateModel>

  $progress: Observable<any>
  $bookmark: Observable<any>

  parentWindow;

  constructor(
      private route: ActivatedRoute,
      private parser: ParseMediaService,
      private store: Store,
      private post: PostService
    ) {
      this.$stream = fromEvent(window, 'message').pipe(
        filter((event: any) =>
          event.data.type === 'Post from LMS' &&
          !(event.source instanceof MessagePort)
          && !(event.source instanceof ServiceWorker)
        )
      )

      if (this.env.production === false) {
      // fake stuff normally in main.js
        // window.addEventListener('message', mockMainListener)
        // setTimeout(() => {
        //   window.postMessage({ type: 'Post from LMS', payload: { type: 'Load Config', data: config }}, '*')
        // }, 500)
      }

      this.store.pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      ).subscribe((state: any) => {
        // console.log('state', state)
        if (state.hydrated) {
          this.post.submit({
            type: 'Set State',
            data: {
              bookmark: state.bookmark,
              progress: state.progress,
              score: state.score,
              status: state.status
            }
          })
        }
      })
    }
  env = environment;
  $route;

  ngOnInit() {
    this.streamSubscriber = this.$stream.subscribe((event: any) => {
      let payload = event.data.payload || { type: null }
      // console.log('stream', event)
      switch (payload.type) {
        case 'Load State':
          this.store.dispatch(loadState({ remoteState: event.data.payload.data }))
          this.parentWindow = event
          break;
        case 'Load Config':
          /**
           * we listen for the set-config message from main.js, which
           * includes host and course data. we then dispatch a setConfig
           * to load the config into our state manager
           */
          this.store.dispatch(setConfig({ config: event.data.payload.data }))
          this.parentWindow = event
          break;
        /**
         * These map 'Set' posts LMS -> Platform to redux actions, bussing LMS data
         * to our state manager
         */
        case 'Load Bookmark':
          this.store.dispatch(setBookmark({ bookmark: payload.data }))
          break;
        case 'Load Progress':
          this.store.dispatch(loadProgress({ progress: payload.data }))
          break;
        case 'Load Score':
          let loadedScore = parseInt(payload.data) || null
          this.store.dispatch(loadScore({ score: {
            intScore: loadedScore, intMaxScore: 100, intMinScore: 0
          } }))
          break;
        case 'Load Status':
          this.store.dispatch(setStatus({ status: payload.data }))
          break;
      }
    })

    /**
     * this subject grabs the main.js source window from service state
     * and posts our message to it
     */
    this.postSubscriber = this.post.getPost().pipe(
    ).subscribe(data => {
      // console.log('post sub', data, this.parentWindow)
         
      if (this.parentWindow) {
        this.parentWindow.source.postMessage(data, '*')
      }
   
      
    })

  }

  ngOnDestroy() {
    this.streamSubscriber.unsubscribe()
    this.postSubscriber.unsubscribe()
  }
}




