<div class="container-fluid pb-2">
  <div class="row">
    <div class="col-lg-6 mt-5 pb-5 mx-auto">
      <div class="media-container">
        <!-- Shows when course is Non-CE -->
        <app-interactive
          [data]="media"
          *ngIf="defaultScoreScreen; else elseBlock"
        ></app-interactive>

        <ng-template #elseBlock>
          <!-- Shows when course is CE -->
          <div class="col-10 mx-auto text-center custom">
            <h3>{{ data.copy[0].title }}</h3>
            <h3>{{ data.copy[0].subtitle }}</h3>
            <h4 class="pt-2">
              {{ data.copy[0].finalScore }}
              <span class="custom-color">{{ score }}%</span>
            </h4>
            <p class="py-4">{{ data.copy[0].instructions }}</p>
            <hr />
            <div>
              <button class="retakeBtn" (click)="retakeAssessment()">
                Re-Take Assessment
              </button>
            </div>

            <div class="summary">
              <small>
                <i>
                  For questions regarding the clinical use of Bayer products,
                  email the Clinical Performance Center (CPC) at
                  <b>clinicalperformancecenter@bayer.com</b> or call
                  <b>1-800-633-7237</b> Option <b>1x1x2</b> (Available Monday –
                  Friday from 9:00 AM – 6:00 PM EST)
                </i>
              </small>
            </div>
          </div>
        </ng-template>
      </div>

      <div *ngIf="devShow">
        <button (click)="devAddAttempt()">Add Attempt</button>
        <button (click)="devSetPass()">Set pass</button>
        <button (click)="devSetFail()">Set fail</button>
        <button (click)="devSetScore()">Set score</button>
        <input type="number" [(ngModel)]="devInput" />
      </div>
    </div>
  </div>
</div>
