import { MediaModule } from './../media/media.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageColumnsComponent } from './image-columns/image-columns.component';
import { VideoColumnsComponent } from './video-columns/video-columns.component';
import { VideoHeroComponent } from './video-hero/video-hero.component';
import { InteractiveHeroComponent } from './interactive-hero/interactive-hero.component';
import { InteractiveSimpleComponent } from './interactive-simple/interactive-simple.component';
import { PostAssessmentComponent } from './post-assessment/post-assessment.component';
import { SafePipe } from './safe.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ImageColumnsComponent, VideoColumnsComponent, VideoHeroComponent, InteractiveHeroComponent, InteractiveSimpleComponent, SafePipe, PostAssessmentComponent],
  imports: [
    CommonModule,
    MediaModule,
    FormsModule
  ],
  exports: [
    ImageColumnsComponent,
    VideoColumnsComponent,
    VideoHeroComponent,
    InteractiveHeroComponent,
    InteractiveSimpleComponent,
    PostAssessmentComponent
  ]
})
export class PagesModule { }
