import { Component, OnInit, Input, AfterViewInit } from "@angular/core";
import videojs from "video.js";
import canauto from "can-autoplay";
import { MediaService } from "../../services/media.service";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";

@Component({
  selector: "app-video",
  templateUrl: "./video.component.html",
  styleUrls: ["./video.component.scss"],
})
export class VideoComponent implements OnInit, AfterViewInit {
  @Input() data: any = {};
  @Input() large = false;

  public vjs: videojs.Player;
  baseUrl = "";

  constructor(private store: Store, private media: MediaService) {}

  ngOnInit() {
    this.store
      .pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      )
      .subscribe((state: any) => {
        if (state.config.baseUrl) {
          this.baseUrl = state.config.baseUrl;
        } else {
          this.baseUrl = "../../../assets/media-content/";
        }
      });
  }

  ngAfterViewInit() {
    const options = {
      sources: [
        {
          src: this.src,
          type: "video/mp4",
        },
      ],
      responsive: true,
      autoplay: true,
      width: this.data.width,
      height: this.data.height,
    };
    this.vjs = videojs("player", options);
    canauto.video().then(({ result }) => {
      if (result === true) {
        const play = this.vjs.play();
        play.catch((error) => console.error(error));
      }
    });

    this.vjs.on("ended", () => {
      let completed = this.vjs.ended();
      let mediaObject = {
        value: completed,
      };
      this.media.set(mediaObject);
    });
  }
  get src() {
    return (
      this.baseUrl + this.data.course + "/video/" + this.data.video + ".mp4"
    );
  }
}
