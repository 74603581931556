import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-video-hero',
  templateUrl: './video-hero.component.html',
  styleUrls: ['./video-hero.component.scss']
})
export class VideoHeroComponent implements OnInit {
  @Input() data: any = {};
  @Input() course = '';
    constructor() { }

    ngOnInit() {
    }
    get media() {
      return {
        course: this.course,
        video: this.data.media[0].file[0],
        width: 600,
        height: 600
      };
    }


}
