import { ActivatedRoute } from "@angular/router";
import { SidenavComponent } from "ng-uikit-pro-standard";
import { Component, OnInit, Input, ViewChild } from "@angular/core";
import { Store } from "@ngrx/store";
import { filter, map } from "rxjs/operators";
import { MediaService } from "../../services/media.service";

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
  styleUrls: ["./navigation.component.scss"],
})
export class NavigationComponent implements OnInit {
  @Input() data: any = {};
  @Input() route: any = {};
  @ViewChild(SidenavComponent, { static: true }) sidenav: SidenavComponent;

  first = true;
  progress: any = {};
  disableNav: boolean = false;
  disableNavItem: boolean = false;
  $progress;
  noPostAssessment: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private store: Store,
    private media: MediaService
  ) {}

  ngOnInit() {
    this.router.params.subscribe((data) => {
      if (this.first) {
        this.first = false;
      } else {
        window.location.reload();
      }
    });

    this.store
      .pipe(
        map((store: any) => store.state),
        filter((state: any) => state.config !== null)
      )
      .subscribe((state: any) => {
        this.progress = state.progress;
        if (state.config.noPostAssessment) {
          this.noPostAssessment = state.config.noPostAssessment;
        }
      });
  }

  disableSideNav(chapter?, page?) {
    let key = [chapter, page].join("-");
    let disable: boolean;

    if (this.progress.linear === true) {
      disable = true;
      if (this.progress[key]) {
        disable = false;
      } else {
        disable = true;
      }
    } else {
      disable = false;
    }

    return disable;
  }

  toggle() {
    this.sidenav.toggle();
  }

  get content() {
    return this.data && this.data.hasOwnProperty("content")
      ? this.data.content
      : {};
  }
  get course() {
    return this.route && this.route.hasOwnProperty("course")
      ? this.route.course
      : "";
  }
  get chapter() {
    return parseInt(this.route.chapter, 10);
  }
  get page() {
    return parseInt(this.route.page, 10);
  }
  get progressKey() {
    return this.chapter + "-" + this.page;
  }

  url(chapter, page) {
    return [this.course, chapter, page].join("/");
  }
}
