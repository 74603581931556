import { PagesModule } from './pages/pages.module';
import { PageNotFoundComponent } from './layout/page-not-found/page-not-found.component';
import { ContentComponent } from './layout/content/content.component';
import { ScormBusModule } from './scorm-bus/scorm-bus.module';
import { LayoutModule } from './layout/layout.module';
import { RouterModule, Routes } from '@angular/router';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from './app.component';

import { MDBSpinningPreloader, MDBBootstrapModulesPro, ToastModule } from 'ng-uikit-pro-standard';
import { StoreModule } from '@ngrx/store';
import { stateReducer } from './scorm-bus/store/reducer';
import { EffectsModule } from '@ngrx/effects';
import { Effects } from './scorm-bus/store/effects';



const routes: Routes = [
  { 
    path: ':course/:chapter/:page', 
    component: ContentComponent 
  }
];

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ToastModule.forRoot(),
    MDBBootstrapModulesPro.forRoot(),
    LayoutModule,
    ScormBusModule,
    PagesModule,
    RouterModule.forRoot(
      routes, { useHash: true, relativeLinkResolution: 'legacy' }
      // { enableTracing: true }
    ),
    StoreModule.forRoot({ state: stateReducer }),
    EffectsModule.forRoot([Effects])
  ],
  providers: [MDBSpinningPreloader],
  bootstrap: [AppComponent],
  schemas:      [ NO_ERRORS_SCHEMA ]
})
export class AppModule { }
